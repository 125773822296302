.user_header {
    width: 100%;
    height: 11%;
    background-color: white;
    border-radius: 8px;
    padding: 0.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user_header_section {
    width: 25%;
    height: 100%;
    background-color: #505DF7;
    display: flex;
    color: white;
    font-size: 0.9em;
    font-weight: 200;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
}

.small_user_header_section {
    width: 11%;
}

/* order table */

.user_table {
    margin-top: 1.75%;
    width: 100%;
    height: 85%;
    background-color: white;
    border-radius: 8px;
    overflow: scroll;
    position: relative;
    padding: 0.25% 0 0.25% 0;
}

.admin_user_empty {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 200;
}
