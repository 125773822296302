.center_container {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.main_center_content_wrapper {
    height: 100vh;
    width: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main_outer_content {
    height: 7.5vh;
    width: 95vw;
}

.main_outer_heading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.1em;
    font-weight: 200;
    padding-left: 2.5%;
}

.right_bound_outer_content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.main_center_content {
    width: 95vw;
    height: 85vh;
    border-radius: 12px 0 0 12px;
    background-color: rgba(241, 241, 241, 0.6);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1% 0.5% 1% 0.5%;
}

.start_main_center_content {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
}
