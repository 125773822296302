.analytics_list_item_wrapper {
    width: 31%;
    height: 67.5%;
    border-radius: 12px;
    background-color: white;
    cursor: pointer;
    padding: 0 1.25% 0 1.25%;
    margin: 0 1.5% 1% 0;
    transition: 0.3s ease-in-out;
}

.analytics_list_item_wrapper:hover {
    transition: 0.3s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.analytics_item_title {
    font-weight: 200;
    margin-top: 7.5%;
    line-height: 3.25vh;
    font-size: 0.9em;
}

.analytics_list_item_separator {
    width: 100%;
    height: 1px;
    background-color: rgba(241, 241, 241, 0.6);
    margin: 4.5% 0 4.5% 0;
}

.analytics_ratio_wrapper {
    width: 100%;
    height: 8.75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75em;
    font-weight: 200;
}
