.main_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.top_section {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.heading_text {
    font-size: 45px;
    margin-left: 2.5%;
    font-weight: bold;
    color: black;
}


/* ============================================= */
.main_verify_section {
    height: 50%;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 115px rgba(50, 59, 74, 0.4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.main_sub_large {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: skew(-20deg);
    margin-left: -5%;
    box-shadow: 10px 0 5px -2px rgba(50, 59, 74, 0.4);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.input_wrapper {
    width: 70%;
    height: 100%;
    transform: skew(20deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
}

.input_section {
    width: 80%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input_desc {
    height: 30%;
    width: 70%;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    letter-spacing: 1px;
    font-weight: lighter;
}

.input_field {
    height: 50%;
    width: 70%;
    border: none;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 6px;
    background-color: rgba(241, 241, 241, 0.8);
}

.input_field:focus {
    outline: none;
}

.submit_btn {
    height: 50%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 6px;
    background-color: #505DF7;
    color: white;
    border: none;
}

.main_sub_small {
    height: 100%;
    width: 42.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.3em;
    letter-spacing: 2px;
    color: black;
    font-weight: lighter;
}

.desc_img {
    height: 50%;
    width: auto;
}



/* ============================================== */
.bottom_section {
    width: 100%;
    height: 20%;
}
