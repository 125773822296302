.review_list_container {
    width: 98.5%;
    height: 100%;
    overflow: scroll;
}

/* heading */
.review_list_heading {
    width: 100%;
    height: 11%;
    background-color: white;
    border-radius: 8px;
    padding: 0.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.large_list_field {
    width: 35%;
    height: 100%;
    background-color: #505DF7;
    display: flex;
    color: white;
    font-size: 0.9em;
    font-weight: 200;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
}

.small_list_field {
    width: 10%;
}

.empty_list_field {
    background-color: transparent;
    color: black;
}

.displayed_list_field {
    background-color: rgba(241, 241, 241, 0.6);
    color: black;
}

.empty_review_msg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 200;
    cursor: pointer;
    color: black;
}
