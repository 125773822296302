.review_list_item {
    width: 100%;
    height: 11%;
    background-color: white;
    border-radius: 8px;
    padding: 0.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5% 0 1.5% 0;
    cursor: pointer;
}
