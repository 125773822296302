.user_list_item {
    width: 100%;
    height: 11%;
    background-color: white;
    border-radius: 8px;
    padding: 0.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user_list_section {
    width: 25%;
    height: 100%;
    background-color: rgba(241, 241, 241, 0.6);
    display: flex;
    color: black;
    font-size: 0.9em;
    font-weight: 200;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
}

.small_user_list_section {
    width: 11%;
}

.small_user_list_section:last-child {
    text-decoration: underline;
    cursor: pointer;
}
