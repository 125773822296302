.general_analytics_wrapper {
    width: 32.5%;
    height: 100%;
    background-color: white;
    border-radius: 12px;
    padding: 1% 2% 1% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.no_analytics_txt {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 200;
    border-radius: 12px 0 0 12px;
}

.general_analytics_info_wrapper {
    width: 100%;
    background-color: #505DF7;
    border-radius: 12px;
    padding: 3% 2.5% 3% 2.5%;
    color: white;
    position: relative;
}

.general_analytics_desc {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9em;
    font-weight: 200;
    width: 100%;
    padding: 4%;
    border-bottom: 1px solid white;
}

.general_analytics_val_large {
    font-size: 4em;
    font-weight: bold;
    letter-spacing: 1px;
    width: 100%;
    text-align: center;
    margin-top: 13.5%;
}
